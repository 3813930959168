import { Controller } from "stimulus"

export default class extends Controller
  @targets = ['image', 'icon', 'preserver']

  loadImage: (eve) ->
    file = eve.target.files[0]
    return false unless file

    reader = new FileReader
    reader.onload = (eve2) =>
      @preserverTarget.remove() if @hasPreserverTarget
      @iconTarget.style.display = 'none' if @hasIconTarget
      @imageTarget.src = eve2.target.result
    reader.readAsDataURL file
