import {Controller} from "stimulus"
import AxiosRequest from "../utils/axios-request"

export default class extends Controller
  @targets: ['form']

  cartLocker: ->
    shopping_cart = @data.get('scid')
    customer = @data.get('uid')
    AxiosRequest(
      "lock.json"
      'post'
      {
        shopping_cart
        emeritus: 2016
        customer
      }
    ).then =>
      document.cookie = 'cart' + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;'
      @formTarget.submit()
