import {Controller} from "stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener('turbolinks:load', function () {
      (function (t, r, i, p) {
        i = t.createElement(r);
        i.src = 'https://www.jscache.com/wejs?wtype=excellent&amp;uniq=860&amp;locationId=14165451&amp;lang=en_US&amp;display_version=2';
        i.async = true;
        i.setAttribute('data-turbolinks-track', 'reload');
        p = t.getElementsByTagName(r)[0];
        p.parentNode.insertBefore(i, p);
      })(document, 'script');
    });
  }
}