import { get } from 'axios'
import mapDataToOptions from './map-data-to-options'

fetchOptions = (url, params = {}, victims) ->
  query = get url, params
  query.then ({ data }) =>
    for victim in victims
      dom_victims = document.querySelectorAll victim
      for dom_victim in dom_victims
        old_value = dom_victim.value
        mapped_data = mapDataToOptions data
        dom_victim.innerHTML = '<option></option>'
        dom_victim.appendChild final_data for final_data in mapped_data
        dom_victim.value = old_value
  query.catch (error) =>
    console.error error

export default fetchOptions
