import {Controller} from "stimulus"
import AxiosRequest from "../utils/axios-request"
import Moment from 'moment'

export default class extends Controller
  @targets: ['form']
  connect: ->
    AxiosRequest(
      "https://production.wompi.co/v1/transactions/#{@data.get 'id'}"
    ).then ({data}) =>
      {data} = data
#      console.log 'Wompi data', data
      status = document.getElementById('status')
      status.innerText = data.status
      reference = document.getElementById('reference')
      reference.innerText = data.reference
      value = document.getElementById('value')
      value.innerText = (data.amount_in_cents/100).toLocaleString('en-US', {
        style: 'currency',
        currency: 'COP',
      })
      currency = document.getElementById('currency')
      currency.innerText = data.currency
      date = document.getElementById('date')
      date.innerText = Moment(data.created_at)
      payment = document.getElementById('payment')
      payment.innerText = data.payment_method_type
      card = document.getElementById('card')
      card.innerText = data.payment_method.extra.name
      installments = document.getElementById('installments')
      installments.innerText = data.payment_method.installments
